import React, { Component } from 'react';

class Header extends Component {
  render() {
    let { header } = this.props;
    return (
      <div className="body-copy-header">{header}</div>
    )
  }
}

export default Header;
