import React, { Component } from 'react';
import TextField from 'components/TextField.component';
import Button from 'components/Button.component';
import CurrencyFormat from 'react-currency-format';
import Paragraph from './blog/components/paragraph.component';
import Header from './blog/components/header.component';
import ReactMarkdown from 'react-markdown';
import MarkdownPath from './markdown.md'
import rdmd from "@readme/markdown";
import {
  isFilled,
  isURLValid,
  isEmailValid,
  isLengthValid,
  isNumeric,
  min } from 'common/helpers/validators';
import './sandbox.css';

class Sandbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: {
        value: "",
        error: false
      },
      lastName: {
        value: "",
        error: false
      },
      email: {
        value: "",
        error: false
      },
      url: {
        value: "",
        error: false
      },
      salary: {
        value: "",
        quantity: "",
        error: false,
        errorMessage: "Salary cannot be empty."
      },
      occupation: {
        value: "",
        error: false
      },
      zipcode: {
        value: "",
        error: false
      },
      md: ""
    }
  }

  isFieldValid(validator, key) {
    let isValid = validator(this.state[key].value);

    this.setState({
      [key]: {
        value: this.state[key].value,
        error: !isValid,
        errorMessage: this.state[key].errorMessage
      }
    });

    return isValid;
  }

  isCostMin(validator, value, key, errorMessage) {
    this.setState({
      [key]: {
        quantity: this.state[key].quantity,
        value: this.state[key].value,
        error: !validator(this.state[key].quantity, value),
        errorMessage: errorMessage
      }
    });

    return validator(this.state[key].quantity, value);
  }

  validate() {
    let fields = new Set();
    fields.add(this.isFieldValid(isFilled, "firstName"));
    fields.add(this.isFieldValid(isFilled, "lastName"));
    fields.add(this.isFieldValid(isEmailValid, "email"))
    fields.add(this.isCostMin(min, 60000, "salary", "Minimum salary is $60,000."));
    fields.add(this.isFieldValid(isFilled, "occupation"));
    fields.add(this.isZipcodeValid());
    fields.add(this.isPersonalURLValid());

    return !fields.has(false);
  }

  isPersonalURLValid() {
    let { value } = this.state.url;

    let isValid = isURLValid(value) || value.length === 0;

    this.setState({
      url: {
        ...this.state.url,
        error: !isValid
      }
    });

    return isValid;
  }

  onChangeZipcode(event) {
    let { value } = event.target

    if (value.length > 5) return;

    let quantity = 0;

    let OK = /[0-9+$]/.test(value)

    if (!OK && value.length > 0) return;

    if (value.length > 0) {
      value = parseInt(value);
    } else {
      value = "";
    }

    this.setState({ zipcode: {
        value
      }
    })
  }


  isZipcodeValid() {
    let value = this.state.zipcode.value.toString();
    let isValid = isLengthValid(value, 5);
    let errorMessage = "Zipcode cannot be empty.";

    if (!isValid && value.length > 0) {
      errorMessage = "Zipcode must be five digits.";
    }

    this.setState({
      zipcode: {
        ...this.state.zipcode,
        error: !isValid,
        errorMessage
      }
    });
    return isValid;
  }

  onChange(event) {
    this.setState({ [event.target.name]: {
        ...this.state[event.target.name],
        value: event.target.value,
        error: this.state[event.target.name].error
      }
    })
  }

  onValueChange(values) {
    const {formattedValue, value} = values;
    this.setState({ salary: {
        ...this.state.salary,
        value: formattedValue,
        quantity: value,
      }
    }, ()=> {
      this.setState({ salary: {
        ...this.state.salary,
        //error: !this.isCostMin(min, 60000, "salary", "Package value must be at least $60,000."),
        //errorMessage: this.state["salary"].errorMessage
        }
      });
    });
  }

  submit(event) {
    event.preventDefault();
    if (!this.validate()) {
      return;
    }
  }

  componentWillMount() {
    fetch(MarkdownPath)
      .then((res) => res.text())
      .then((md) => {
        this.setState({ md })
      })
  }

  getSnippet() {
    return rdmd(this.state.md);
  }

  render() {
    let {
      firstName,
      lastName,
      email,
      url,
      salary,
      occupation,
      zipcode
    } = this.state;

    return(
      <div className="container">
        <div>
          {this.getSnippet()}
        </div>
        <Header header="asdfsdfa"/>
        <Paragraph text="asdfsdfa"/>
        <Paragraph text="asdfsdfa"/>
        <Paragraph text="asdfsdfa"/>
        <Paragraph text="asdfsdfa"/>
        <Paragraph text="asdfsdfa"/>
        <Paragraph text="asdfsdfa"/>

        <form onSubmit={this.submit.bind(this)} className="alej-form">
          <TextField
            error={firstName.error}
            value={firstName.value}
            name="firstName"
            errorMessage="First name cannot be empty."
            label="First Name"
            placeholder="First Name"
            onChange={this.onChange.bind(this)} />
          <TextField
            error={lastName.error}
            value={lastName.value}
            name="lastName"
            errorMessage="Last name cannot be empty."
            label="Last Name"
            placeholder="Last Name"
            onChange={this.onChange.bind(this)} />
          <TextField
            error={email.error}
            value={email.value}
            name="email"
            errorMessage="Please enter a vaild e-mail."
            label="E-mail"
            placeholder="E-mail"
            onChange={this.onChange.bind(this)} />
          <TextField
            error={url.error}
            value={url.value}
            name="url"
            errorMessage="Please enter a vaild url."
            label="Personal Website (Optional)"
            placeholder="Personal Website"
            onChange={this.onChange.bind(this)} />
          <CurrencyFormat
            thousandSeparator={true}
            prefix='$'
            customInput={TextField}
            name="salary"
            value={salary.quantity}
            error={salary.error}
            errorMessage={salary.errorMessage}
            label="Desired Salary - Min. $60,000"
            placeholder='Desired Salary'
            onValueChange={this.onValueChange.bind(this)} />
          <TextField
            error={occupation.error}
            value={occupation.value}
            name="occupation"
            errorMessage="Occupation cannot be empty."
            label="Occupation"
            placeholder="Occupation"
            onChange={this.onChange.bind(this)} />
          <TextField
            name="zipcode"
            label="Zipcode"
            error={zipcode.error}
            value={zipcode.value}
            errorMessage={zipcode.errorMessage}
            placeholder="Zipcode"
            onChange={this.onChangeZipcode.bind(this)} />
          <Button
            style={{marginTop: '25px'}}
            type="submit"
            label="Submit"/>
          {/*<div>
            Are you willing to work remote?
          </div>*/}
        </form>
      </div>
    )
  }
}

export default Sandbox;
