import React, { Component } from 'react';
import TextField from 'components/TextField.component';
import Button from 'components/Button.component';
import {
  isFilled
} from 'common/helpers/validators';
import './signupform.css';

class SignUpForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: {
        value: "",
        error: false
      },
      lastName: {
        value: "",
        error: false
      },
      email: {
        value: "",
        error: false
      },
      startdate: {
        value: "",
        error: false
      },
      url: {
        value: "",
        error: false
      },
      salary: {
        value: "",
        quantity: "",
        error: false,
        errorMessage: "Salary cannot be empty."
      },
      occupation: {
        value: "",
        error: false
      },
      zipcode: {
        value: "",
        error: false
      },
      result: ""
    }
  }

  onChange(event) {
    let { name, value } = event.target;
    this.setState({ [name]: {
        ...this.state[name],
        value: event.target.value
      }
    })
  }

  isFieldValid(validator, key) {
    let isValid = validator(this.state[key].value);

    this.setState({
      [key]: {
        value: this.state[key].value,
        error: !isValid,
        errorMessage: this.state[key].errorMessage
      }
    });

    return isValid;
  }

  submit(event) {
    event.preventDefault();
    if (!this.validate()) {
      return;
    }
    let { firstName, lastName, occupation } = this.state;
    this.setState({result: `Success! First Name: ${firstName.value}, Last Name: ${lastName.value}, Occupation: ${occupation.value}`});
  }

  validate() {
    let fields = new Set();
    fields.add(this.isFieldValid(isFilled, "firstName"));
    fields.add(this.isFieldValid(isFilled, "lastName"));
    fields.add(this.isFieldValid(isFilled, "occupation"));

    return !fields.has(false);
  }

  render() {
    let {
      firstName,
      lastName,
      occupation
    } = this.state;

    return(
      <div className="container">
        <form onSubmit={this.submit.bind(this)} className="signup-form">
          <TextField
            error={firstName.error}
            value={firstName.value}
            name="firstName"
            errorMessage="First name cannot be empty."
            label="First Name"
            placeholder="First Name"
            onChange={this.onChange.bind(this)} />
          <TextField
            error={lastName.error}
            value={lastName.value}
            name="lastName"
            errorMessage="Last name cannot be empty."
            label="Last Name"
            placeholder="Last Name"
            onChange={this.onChange.bind(this)} />
          <TextField
            error={occupation.error}
            value={occupation.value}
            name="occupation"
            errorMessage="Occupation cannot be empty."
            label="Occupation"
            placeholder="Occupation"
            onChange={this.onChange.bind(this)} />
          <Button
            style={{marginTop: '25px'}}
            type="submit"
            label="Submit"/>
        </form>
        <div style={{marginTop: '25px'}}>{this.state.result}</div>
      </div>
    )
  }
}

export default SignUpForm;
