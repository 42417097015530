import React from 'react';
import Project from 'components/Project.component.js';
import './SelectedProjects.css';

let ahServices = [
  {
    services: [{
      name: "Front-End Development"
    },{
      name: "User Interface Design"
    },{
      name: "User Experience Design"
    },{
      name: "Back-End Development (as needed)"
    }]
  }
];

let ahTools = [
  {
    tools: [{
      name: "React.js"
    },{
      name: "Redux"
    },{
      name: "Figma"
    },{
      name: "Go (on occasion)"
    }, {
      name: "Node-RED (on occasion)"
    }]
  }
];

let avServices = [
  {
    services: [{
      name: "Translating and updating ETL processes from C# to python3"
    },{
      name: "Maintaing an internal CLI"
    }]
  }
];

let avTools = [
  {
    tools: [{
      name: "Python"
    },{
      name: "pandas"
    },{
      name: "click"
    },{
      name: "django"
    }, {
      name: "PostgreSQL"
    }]
  }
];

let obiServices = [
  {
    services: [{
      name: "Built a web crawler"
    },{
      name: "Data parsing & aggregation"
    }]
  }
];

let obiTools = [
  {
    tools: [{
      name: "Python"
    },{
      name: "BeautifulSoup"
    },{
      name: "MySQL"
    }]
  }
];

let raServices = [
  {
    services: [{
      name: "Employed pattern matching techniques for UXO classification using unsupervised learning techniques and statistical data analysis. Developed code in MATLAB to perform discrimination."
    },{
      name: "Developed a computational model in MATLAB to describe the interaction between magnetic Nanoparticles when exposed to a magnetic dipole."
    }]
  }
];

let raTools = [
  {
    tools: [{
      name: "MATLAB"
    }]
  }
];

function getRelated(related) {
  if (!related) return null;

  return (
    <ul>
      {
        related.map((item) => {
          return <li>{item}</li>
        })
      }
    </ul>
  );
}

function SelectedProjects(props) {
  let {
    className,
    technologies,
    services
  } = props;

  className = className ? className : "";

  return (
    <div className="hero-container segment-container d-flex flex-column align-items-center">
      <div className="segment-wrapper d-flex flex-column align-items-start">
        <div style={{marginBottom: '50px'}} className="header-1-black">Selected Projects & Clients</div>
        <div className="segment-projects">
          <Project
            title="Healthcare Platform"
            duration="July 2019 - Ongoing"
            headline="Working on initial version of their flagship mobile application under the direction of the lead platform engineer and CEO."
            tools={ahTools}
            services={ahServices}
          />
          <Project
            title="Financial Services Consultancy"
            duration="October 2019 - Ongoing"
            headline="Python development for internal and external projects."
            tools={avTools}
            services={avServices}
          />
          <Project
            title="Web Scraper for Masters Thesis"
            duration="December 2015 - February 2016"
            headline="Built a web crawler & scraper in python for a Masters recipient at Tsinghua University to retrieve and organize relevant news from online based on collections of keywords. Thesis was on Corporate Social Responsibility of Chinese International Contractors in China."
            tools={obiTools}
            services={obiServices}
          />
          <Project
            title="Research Assistant"
            duration="January 2011 - May 2012"
            headline="Worked for Professor Fridon Shubitidze in his computational electromagnetics lab."
            tools={raTools}
            services={raServices}
          />
        </div>
      </div>
    </div>
  );
}

export default SelectedProjects;
