import React, { Component } from 'react';
import HammerImg from 'assets/hammer.png';
import './engineer.css';

class EngineerSvg extends Component {
  render() {
    return (
      <svg className="engineer-svg" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 500 500">
        <defs>
        	<path d="M50,250c0-110.5,89.5-200,200-200s200,89.5,200,200s-89.5,200-200,200S50,360.5,50,250" id="textcircle">
        		<animateTransform
              attributeName="transform"
              begin="0s"
              dur="30s"
              type="rotate"
              from="0 250 250"
              to="360 250 250"
              repeatCount="indefinite"
        		/>
        	</path>
        </defs>
      	<use xlinkHref="#redstripe" />
      	<use xlinkHref="#redstripe" transform="rotate(90 250 250)" />
      	<use xlinkHref="#redstripe" transform="rotate(45 250 250)" />
      	<use xlinkHref="#redstripe" transform="rotate(-45 250 250)" />
      	<text dy="70" textLength="1220">
      		<textPath xlinkHref="#textcircle">Engineer • Creator • Builder • </textPath>
      	</text>
        <image x="170" y="135" width="180" height="230"  href={HammerImg} />
      </svg>
    );
  }
}

export default EngineerSvg;


/*

import React, { Component } from 'react';
import HammerImg from '../assets/hammer.png';
import './engineer.css';

class EngineerSvg extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 500 500">
      <defs>
      	<path d="M50,250c0-110.5,89.5-200,200-200s200,89.5,200,200s-89.5,200-200,200S50,360.5,50,250" id="textcircle">
      		<animateTransform
                  attributeName="transform"
                  begin="0s"
                  dur="30s"
                  type="rotate"
                  from="0 250 250"
                  to="360 250 250"
                  repeatCount="indefinite"
      			/>
      	</path>

      		  <image href="https://mdn.mozillademos.org/files/6457/mdn_logo_only_color.png" height="200" width="200"/>

      </defs>
      	<use xlinkHref="#redstripe" />
      	<use xlinkHref="#redstripe" transform="rotate(90 250 250)" />
      	<use xlinkHref="#redstripe" transform="rotate(45 250 250)" />
      	<use xlinkHref="#redstripe" transform="rotate(-45 250 250)" />
      	<text dy="70" textLength="1220">
      		<textPath xlinkHref="#textcircle">Engineer</textPath>
      	</text>
      </svg>
    );
  }
}

export default EngineerSvg;
*/
