import React from 'react';
import SelectedProjects from './SelectedProjects.js';
import Segment from 'components/Segment.component.js';
import RestageGif from 'assets/restage/landingpage.gif';
import SomewhereGoodGif from 'assets/somewheregood.gif';
import RestageLogo from 'assets/restage/logo-purple.svg';
import PeerLogixLogo from 'assets/peerlogix/peerlogix.png';
import EthelsClubLogo from 'assets/ec-logo-white@3x.png';
import FormNoFormLogo from 'assets/formnoform.png';
import SomewhereGoodLogo from 'assets/somewheregood.png';
import ReziLogo from 'assets/rezi/rezi.png';
import ReziGif from 'assets/rezi/rezi.gif';
import EthelsClubGif from 'assets/ethelsclub.gif';
import FormNoFormGif from 'assets/formnoform.gif';

import './work.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab, faTwitter, faYoutube, faInstagram, faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';

library.add(fab, faTwitter, faYoutube, faInstagram, faLinkedin, faGithub);

function Work() {
  let formNoFormResponsibilites = [
    {
      header: "Back-End & API Architecture",
      technologies: [{
        name: "Python3"
      },{
        name: "Flask"
      },{
        name: "SQLAlchemy"
      }]
    },
    {
      header: "Front-End Development & Design",
      technologies: [{
        name: "React.js"
      },{
        name: "Redux"
      },{
        name: "JavaScript"
      },{
        name: "HTML",
        related: ["HTML Video"]
      },{
        name: "CSS"
      }]
    },
    {
      header: "Video Processing",
      technologies: [{
        name: "FFMPEG"
      },{
        name: "HandBrake CLI"
      }]
    },
    {
      header: "Desktop Application Technologies",
      technologies: [{
        name: "Electron"
      }]
    },
    {
      header: "User Experience & User Interface Design",
      technologies: [{
        name: "Figma"
      }]
    },
    {
      header: "Database Design & Development",
      technologies: [{
        name: "PostgreSQL"
      }]
    },{
      header: "DevOps",
      technologies: [{
        name: "AWS",
        related: ["S3", "CloudFront", "RDS"]
      }]
    }
  ];

  let somewhereGoodResponsibilities = [
    {
      header: "Back-End & API Architecture",
      technologies: [{
        name: "Python"
      },
      {
        name: "Flask"
      },
      {
        name: "Connexion"
      }]
    },
    {
      header: "Front-End Development & Design",
      technologies: [{
        name: "React.js"
      },{
        name: "Redux"
      },{
        name: "JavaScript"
      },{
        name: "HTML"
      },{
        name: "CSS"
      }]
    },
    {
      header: "iOS",
      technologies: [{
        name: "UIKit"
      },{
        name: "SwiftUI"
      },{
        name: "Fastlane"
      }]
    },
    {
      header: "DevOps",
      technologies: [
        {
          name: "Docker"
        },
        {
          name: "AWS",
          related: ["S3", "CloudFront"]
        }]
    }
  ];


  let ethelsClubResponsibilities = [
    {
      header: "Back-End & API Architecture",
      technologies: [{
        name: "Rails 5.x"
      }]
    },
    {
      header: "Front-End Development & Design",
      technologies: [{
        name: "React.js"
      },{
        name: "Redux"
      },{
        name: "JavaScript"
      },{
        name: "HTML"
      },{
        name: "CSS"
      }]
    },
    {
      header: "User Experience & User Interface Design",
      technologies: [{
        name: "Zeplin"
      }]
    },
    {
      header: "Database Design & Development",
      technologies: [{
        name: "PostgreSQL"
      }]
    },{
      header: "DevOps",
      technologies: [{
        name: "AWS",
        related: ["S3"]
      }, {
        name: "Heroku"
      }]
    }
  ];

  let restageResponsibilities = [
    {
      header: "Back-End & API Architecture",
      technologies: [{
        name: "Java"
      },{
        name: "Jersey"
      },{
        name: "Dropwizard"
      },{
        name: "Python"
      }, {
        name: "Flask"
      }]
    },
    {
      header: "Front-End Development & Design",
      technologies: [{
        name: "React.js"
      },{
        name: "Redux"
      },{
        name: "React Antd"
      },{
        name: "Bootstrap"
      },{
        name: "JavaScript"
      },{
        name: "HTML"
      },{
        name: "CSS"
      }]
    },
    {
      header: "User Experience & User Interface Design",
      technologies: [{
        name: "Figma"
      },{
        name: "Wireframing"
      }]
    },
    {
      header: "Data Processing, ETL & Scraping",
      technologies: [{
        name: "Python3"
      },{
        name: "BeautifulSoup"
      },{
        name: "Elasticsearch"
      }]
    },{
      header: "Database Design & Development",
      technologies: [{
        name: "MySQL"
      }]
    },{
      header: "DevOps",
      technologies: [{
        name: "AWS",
        related: ["EC2", "S3", "RDS", "CloudFront", "Route53", "Elastic Beanstalk"]
      }, {
        name: "Bash Scripting"
      }, {
        name: "Cron jobs"
      }]
    }
  ];

  let peerlogixResponsibilities = [
    {
      header: "Back-End & API Architecture",
      technologies: [{
        name: "Rails 3 & 4"
      }]
    },
    {
      header: "Front-End Development & Design",
      technologies: [{
        name: "Angular 1.x"
      },{
        name: "Bootstrap"
      },{
        name: "Angular Material"
      },{
        name: "Webpack"
      }]
    },
    {
      header: "User Experience & User Interface Design",
      technologies: [{
        name: "Wireframing"
      }]
    },
    {
      header: "Data Processing, ETL & Scraping",
      technologies: [{
        name: "Python2"
      },{
        name: "BeautifulSoup"
      },{
        name: "Elasticsearch"
      }]
    },{
      header: "Database Design & Development",
      technologies: [{
        name: "PostgreSQL",
      },{
        name: "MySQL",
      },{
        name: "Redshift",
      }]
    },{
      header: "DevOps",
      technologies: [{
        name: "AWS",
        related: ["EC2", "S3", "RDS", "Redshift"]
      }, {
        name: "Bash Scripting"
      }, {
        name: "Cron jobs"
      } ]
    }
  ];

  let reziResponsibilities = [
    {
      header: "Front-End Development & Design",
      technologies: [{
        name: "Google Web Toolkit (Java)"
      },{
        name: "Bootstrap"
      },{
        name: "JavaScript"
      },{
        name: "HTML"
      },{
        name: "CSS"
      }]
    },
    {
      header: "API Architecture & Implementation",
      technologies: [{
        name: "Java"
      },{
        name: "Jersey"
      },{
        name: "JAX-RS"
      }]
    },
    {
      header: "Data Processing & ETL",
      technologies: [{
        name: "Java"
      },{
        name: "Elasticsearch"
      }]
    },{
      header: "Database Design & Development",
      technologies: [{
        name: "MySQL"
      }]
    },{
      header: "DevOps",
      technologies: [{
        name: "AWS",
        related: ["EC2", "S3", "RDS", "Elastic Beanstalk"]
      },{
        name: "Docker"
      }]
    }
  ];

  return (
    <div className="d-flex align-items-center flex-column">
      <div style={{marginBottom: '50px'}} className="work-header segment-wrapper header-1-black">Work</div>
      <div className="segment-outer-bound">
        <Segment
          responsibilities={somewhereGoodResponsibilities}
          headline={()=>{
            return (
              <div className="body-copy-small">

              </div>
            )
          }}
          linkWebsite="https://somewheregood.com"
          logoImageClass="somewheregood-logo-img"
          className="somewheregood-container"
          gifUrl={SomewhereGoodGif}
          gifCaption={()=>{
            return <>The homepage for Somewhere Good.</>
          }}
          gifCaptionAlt="The homepage for Somewhere Good."
          logoUrl={SomewhereGoodLogo}
          logoAlt="Somewhere Good Logo"
          companyName="Somewhere Good"
          title="CTO"
          duration="May 2020 - June 2021, CTO from August 2020 onwards"
        />
      </div>
      <div className="segment-outer-bound">
        <Segment
          responsibilities={formNoFormResponsibilites}
          headline={()=>{
            return (
              <div className="body-copy-small">
                I worked on the platform under the guidance, design, and vision of the Head Of Creative, <a target="_blank" rel="noopener noreferrer" className="link-color" href="https://www.annikaizora.com/">Annika Hansteen-Izora</a> and the Founder and CEO, <a target="_blank" rel="noopener noreferrer" className="link-color" href="https://www.ethelsclub.com/about">Naj Austin</a>.
              </div>
            )
          }}
          linkWebsite="https://www.formnoform.com"
          logoImageClass="formnoform-logo-img"
          className="formnoform-container"
          gifUrl={FormNoFormGif}
          gifCaption={()=> {
            return <>The Form No Form platform.</>
          }}
          gifCaptionAlt="The Form No Form platform."
          logoUrl={FormNoFormLogo}
          logoAlt="Form No Form Logo"
          companyName="Form No Form"
          title="Software Engineer"
          duration="May 2020 - Present"
        />
      </div>
      <div className="segment-outer-bound">
        <Segment
          responsibilities={somewhereGoodResponsibilities}
          headline={()=>{
            return (
              <div className="body-copy-small">
                I helped build the landing page with the guidance, design, and vision of the Head Of Creative, <a target="_blank" rel="noopener noreferrer" className="link-color" href="https://www.annikaizora.com/">Annika Hansteen-Izora</a> and the Founder and CEO, <a target="_blank" rel="noopener noreferrer" className="link-color" href="https://www.ethelsclub.com/about">Naj Austin</a>.
              </div>
            )
          }}
          linkWebsite="https://somewheregood.com"
          logoImageClass="somewheregood-logo-img"
          className="somewheregood-container"
          gifUrl={SomewhereGoodGif}
          gifCaption={()=>{
            return <>The homepage for Somewhere Good.</>
          }}
          gifCaptionAlt="The homepage for Somewhere Good."
          logoUrl={SomewhereGoodLogo}
          logoAlt="Somewhere Good Logo"
          companyName="Somewhere Good"
          title="Software Engineer"
          duration="May 2020 - Present"
        />
      </div>
      <div className="segment-outer-bound">
        <Segment
          responsibilities={ethelsClubResponsibilities}
          headline={()=>{
            return (
              <div className="body-copy-small">
                I helped update the landing page with the guidance, design, and vision of the Head Of Creative, <a target="_blank" rel="noopener noreferrer" className="link-color" href="https://www.annikaizora.com/">Annika Hansteen-Izora</a> and the Founder and CEO, <a target="_blank" rel="noopener noreferrer" className="link-color" href="https://www.ethelsclub.com/about">Naj Austin</a>.
              </div>
            )
          }}
          linkWebsite="https://ethelsclub.com"
          logoImageClass="restage-logo-img"
          className="ethels-container"
          gifUrl={EthelsClubGif}
          gifCaption={()=>{
            return <>Ethel's Club home and about pages.</>
          }}
          gifCaptionAlt="Ethel's Club home and about pages."
          logoUrl={EthelsClubLogo}
          logoAlt="Ethel's Club Logo"
          companyName="Ethel's Club"
          title="Software Engineer"
          duration="February 2020 - Present"
        />
      </div>
      <div className="segment-outer-bound">
        <Segment
          responsibilities={restageResponsibilities}
          headline={()=>{
            return (
              <div className="body-copy-small">
                I am building the MVP under the direction of the Founder and CEO, <a target="_blank" rel="noopener noreferrer" className="link-color" href="https://kiamber.com/">Kiamber Staples</a>, along with another engineer. I also advise on business-related matters, help with content marketing, and &nbsp;
                <a className="link-color" href="https://restagex.com/blog/create-sponsorship-packages-sponsors-will-love">write blog posts</a>.
              </div>
            )
          }}
          linkWebsite="https://restagex.com"
          logoImageClass="restage-logo-img"
          className="restage-container"
          gifUrl={RestageGif}
          gifCaptionAlt="Rezi Apartment Search, Property Description Pages, Photo Gallery, Modal Login, and About Us Pages. The design was by REZI's Head of Product, Artin Kiassati, and the first two years of development for the REZI platform was done as a team including the CTO and Co-Founder, Harsh Yadav, myself, and another Senior Engineer, Rajveer Sidhu."
          gifCaption={()=> {
            return <>Restage Landing Pages for Event Organizers, Sponsors, and Our Blog and Resources Page. The development and implementation of these screens were by me, and I have built much of the Restage platform. We have another engineer who works on a part-time basis who built our Machine Learning models and works across different parts of the stack.</>
          }}
          logoUrl={RestageLogo}
          logoAlt="Restage Logo"
          companyName="Restage"
          title="Interim CTO"
          duration="March 2019 - Present"
        />
      </div>
      <div className="segment-outer-bound">
        <Segment
          responsibilities={reziResponsibilities}
          headline={()=>{
            return <div className="body-copy-small">I worked under the direction of the CTO on the founding team to create proprietary technology, analytical tools, and financial products to improve the rental experience for landlords and tenants.</div>
          }}
          linkWebsite="https://rentrezi.com"
          logoImageClass="rezi-logo-img"
          className="rezi-container"
          gifUrl={ReziGif}
          gifCaptionAlt="Rezi Apartment Search, Property Description Pages, Photo Gallery, Modal Login, and About Us Pages. The design was by REZI's Head of Product, Artin Kiassati, and the first two years of development for the REZI platform was done as a team including the CTO and Co-Founder, Harsh Yadav, myself, and another Senior Engineer, Rajveer Sidhu."
          gifCaption={()=>{
            return <>Rezi Apartment Search, Property Description Pages, Photo Gallery, Modal Login, and About Us Pages. The design was by REZI's Head of Product, Artin Kiassati, and the first two years of development for the REZI platform was done as a team including the CTO and Co-Founder, Harsh Yadav, myself, and another Senior Engineer, Rajveer Sidhu."</>
          }}
          logoUrl={ReziLogo}
          logoAlt="Rezi Logo"
          companyName="Rezi"
          title="Senior Software Engineer"
          duration="April 2016 - March 2019"
        />
      </div>
      <div className="segment-outer-bound">
        <Segment
          responsibilities={peerlogixResponsibilities}
          headline={()=>{
            return <div className="body-copy-small">As PeerLogix first hire, I worked as a Full Stack Engineer with additional responsibilities in defining and driving product functionality and strategy. I held interviews with our target users in order to harvest and digest feedback into meaningful requirements and deliverables for PeerLogix products.</div>
          }}
          linkWebsite="https://peerlogix.com"
          logoImageClass="peerlogix-logo-img"
          className="peerlogix-container"
          logoUrl={PeerLogixLogo}
          logoAlt="PeerLogix Logo"
          companyName="PeerLogix"
          title="Senior Software Engineer"
          duration="June 2014 - March 2016"
        />
      </div>
      <SelectedProjects />
    </div>
  );
}

export default Work;
