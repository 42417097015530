import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import EngineerSvg from './engineer';
import SupporterSvg from './supporter';
import ThinkingSvg from './thinker';
import './Hero.css';

const URLS = {
  TWITTER: "https://twitter.com/alejluperon",
  LINKEDIN: "https://www.linkedin.com/in/alejandroluperon",
  INSTAGRAM: "https://www.instagram.com/alejandroluperon",
  GITHUB: "https://github.com/alejandroluperon"
}

function onClickIcon(url) {
  window.open(url, "_blank")
}

function Hero(props) {
  let { TWITTER, LINKEDIN, INSTAGRAM, GITHUB } = URLS;

  return (
    <div className="hero-container segment-container d-flex justify-content-center">
      <div className="segment-wrapper d-flex flex-column align-items-start justify-content-between">
        <div className="svg-row d-flex justify-content-between">
          <EngineerSvg />
          {/*<SupporterSvg />
          <ThinkingSvg />*/}
        </div>

        <div className="d-flex flex-column align-items-start">
          {/*<div style={{textAlign: 'start'}} className="header-1-white">I'm currently Interim CTO at <a className="link-color" target="_blank" rel="noopener noreferrer" href="https://restagex.com">Restage</a>.  I am currently open to any opportunities an can allot up to 25 hours per week.</div>
*/}          <div style={{textAlign: 'start'}} className="header-1-black">Software Engineer</div>

          <div style={{textAlign: 'start'}} className="body-copy-black">Please click <a href="/about">here</a> to learn about me, or <a href="/work-history">here</a> to view my work history.</div>
        </div>
        <div className="hero-social-icons d-flex flex-row">
          <FontAwesomeIcon onClick={onClickIcon.bind(this, TWITTER)} size="3x" className="hero-social-icon" icon={["fab", "twitter"]} />
          <FontAwesomeIcon onClick={onClickIcon.bind(this, LINKEDIN)} size="3x" className="hero-social-icon" icon={["fab", "linkedin"]} />
          <FontAwesomeIcon onClick={onClickIcon.bind(this, INSTAGRAM)} size="3x" className="hero-social-icon" icon={["fab", "instagram"]} />
          <FontAwesomeIcon onClick={onClickIcon.bind(this, GITHUB)} size="3x" className="hero-social-icon" icon={["fab", "github"]} />
        </div>
      </div>
    </div>
  );
}

export default Hero;
