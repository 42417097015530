import React from 'react';
import Hero from './sections/Hero.js';
import About from './sections/About.js';
//import SelectedProjects from './sections/SelectedProjects.js';
import Segment from 'components/Segment.component.js';
import RestageGif from 'assets/restage/landingpage.gif';
import RestageLogo from 'assets/restage/logo-purple.svg';
import PeerLogixLogo from 'assets/peerlogix/peerlogix.png';
import ReziLogo from 'assets/rezi/rezi.png';
import ReziGif from 'assets/rezi/rezi.gif';

import logo from 'logo.svg';
import './home.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab, faTwitter, faYoutube, faInstagram, faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';

library.add(fab, faTwitter, faYoutube, faInstagram, faLinkedin, faGithub);

function Home() {
  let restageResponsibilities = [
    {
      header: "Back-End & API Architecture",
      technologies: [{
        name: "Java"
      },{
        name: "Jersey"
      },{
        name: "Dropwizard"
      },{
        name: "Python"
      }, {
        name: "Flask"
      }]
    },
    {
      header: "Front-End Development & Design",
      technologies: [{
        name: "React.js"
      },{
        name: "Redux"
      },{
        name: "React Antd"
      },{
        name: "Bootstrap"
      },{
        name: "JavaScript"
      },{
        name: "HTML"
      },{
        name: "CSS"
      }]
    },
    {
      header: "User Experience & User Interface Design",
      technologies: [{
        name: "Figma"
      },{
        name: "Wireframing"
      }]
    },
    {
      header: "Data Processing, ETL & Scraping",
      technologies: [{
        name: "Python3"
      },{
        name: "BeautifulSoup"
      },{
        name: "Elasticsearch"
      }]
    },{
      header: "Database Design & Development",
      technologies: [{
        name: "MySQL"
      }]
    },{
      header: "DevOps",
      technologies: [{
        name: "AWS",
        related: ["EC2", "S3", "RDS", "CloudFront", "Route53", "Elastic Beanstalk"]
      }, {
        name: "Bash Scripting"
      }, {
        name: "Cron jobs"
      }]
    }
  ];

  let peerlogixResponsibilities = [
    {
      header: "Back-End & API Architecture",
      technologies: [{
        name: "Rails 3 & 4"
      }]
    },
    {
      header: "Front-End Development & Design",
      technologies: [{
        name: "Angular 1.x"
      },{
        name: "Bootstrap"
      },{
        name: "Angular Material"
      },{
        name: "Webpack"
      }]
    },
    {
      header: "User Experience & User Interface Design",
      technologies: [{
        name: "Wireframing"
      }]
    },
    {
      header: "Data Processing, ETL & Scraping",
      technologies: [{
        name: "Python2"
      },{
        name: "BeautifulSoup"
      },{
        name: "Elasticsearch"
      }]
    },{
      header: "Database Design & Development",
      technologies: [{
        name: "PostgreSQL",
      },{
        name: "MySQL",
      },{
        name: "Redshift",
      }]
    },{
      header: "DevOps",
      technologies: [{
        name: "AWS",
        related: ["EC2", "S3", "RDS", "Redshift"]
      }, {
        name: "Bash Scripting"
      }, {
        name: "Cron jobs"
      } ]
    }
  ];

  let reziResponsibilities = [
    {
      header: "Front-End Development & Design",
      technologies: [{
        name: "Google Web Toolkit (Java)"
      },{
        name: "Bootstrap"
      },{
        name: "JavaScript"
      },{
        name: "HTML"
      },{
        name: "CSS"
      }]
    },
    {
      header: "API Architecture & Implementation",
      technologies: [{
        name: "Java"
      },{
        name: "Jersey"
      },{
        name: "JAX-RS"
      }]
    },
    {
      header: "Data Processing & ETL",
      technologies: [{
        name: "Java"
      },{
        name: "Elasticsearch"
      }]
    },{
      header: "Database Design & Development",
      technologies: [{
        name: "MySQL"
      }]
    },{
      header: "DevOps",
      technologies: [{
        name: "AWS",
        related: ["EC2", "S3", "RDS", "Elastic Beanstalk"]
      },{
        name: "Docker"
      }]
    }
  ];

  return (
    <div className="App app-container d-flex align-items-center flex-column">
      <Hero />
      {/*<About />
      <div className="segment-outer-bound">
        <Segment
          responsibilities={restageResponsibilities}
          headline={()=>{
            return (
              <div className="body-copy-small">
                I am building the MVP under the direction of the CEO along with another engineer. I also advise on business-related matters, help with content marketing, and &nbsp;
                <a className="link-color" href="https://restagex.com/blog/create-sponsorship-packages-sponsors-will-love">write blog posts</a>.
              </div>
            )
          }}
          linkWebsite="https://restagex.com"
          logoImageClass="restage-logo-img"
          className="restage-container"
          gifUrl={RestageGif}
          gifCaption="Restage Landing Pages for Event Organizers, Sponsors, and Our Blog and Resources Page. The development and implementation of these screens were by me, and I have built much of the Restage platform. We have another engineer who works on a part-time basis who built our Machine Learning models and works across different parts of the stack."
          logoUrl={RestageLogo}
          logoAlt="Restage Logo"
          companyName="Restage"
          title="Interim CTO"
          duration="March 2019 - Present"
        />
      </div>
      <div className="segment-outer-bound">
        <Segment
          responsibilities={reziResponsibilities}
          headline={()=>{
            return <div className="body-copy-small">I worked under the direction of the CTO on the founding team to create proprietary technology, analytical tools, and financial products to improve the rental experience for landlords and tenants.</div>
          }}
          linkWebsite="https://rentrezi.com"
          logoImageClass="rezi-logo-img"
          className="rezi-container"
          gifUrl={ReziGif}
          gifCaption="Rezi Apartment Search, Property Description Pages, Photo Gallery, Modal Login, and About Us Pages. The design was by REZI's Head of Product, Artin Kiassati, and the first two years of development for the REZI platform was done as a team including the CTO and Co-Founder, Harsh Yadav, myself, and another Senior Engineer, Rajveer Sidhu."
          logoUrl={ReziLogo}
          logoAlt="Rezi Logo"
          companyName="Rezi"
          title="Senior Software Engineer"
          duration="April 2016 - March 2019"
        />
      </div>
      <div className="segment-outer-bound">
        <Segment
          responsibilities={peerlogixResponsibilities}
          headline={()=>{
            return <div className="body-copy-small">As PeerLogix first hire, I worked as a Full Stack Engineer with additional responsibilities in defining and driving product functionality and strategy. I held interviews with our target users in order to harvest and digest feedback into meaningful requirements and deliverables for PeerLogix products.</div>
          }}
          linkWebsite="https://peerlogix.com"
          logoImageClass="peerlogix-logo-img"
          className="peerlogix-container"
          logoUrl={PeerLogixLogo}
          logoAlt="PeerLogix Logo"
          companyName="PeerLogix"
          title="Senior Software Engineer"
          duration="June 2014 - March 2016"
        />
      </div>
      <SelectedProjects />*/}
    </div>
  );
}

export default Home;
