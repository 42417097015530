import React, { Component } from 'react';

class Paragraph extends Component {
  render() {
    let { text } = this.props;
    return(
      <p className="body-copy">{text}</p>
    )
  }
}

export default Paragraph;
