import React from 'react';
import Home from 'screens/home/home.screen';
import About from 'screens/about/about.screen';
import Work from 'screens/work/work';
import Sandbox from 'screens/sandbox.screen';
import SignUpForm from 'screens/signupform.screen';
import SignUpFormTextOnly from 'screens/signupform-textonly.screen';
import SignUpFormWithButton from 'screens/signupform-withbutton.screen';
import SignUpFormWithUrlEmail from 'screens/signupform-withurlemail.screen';
import './App.css';
import './text.css';
import './lang.css';
import { Routes } from 'constants/routes';
import NavBar from 'components/navbar';
import { BrowserRouter, Route } from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab, faTwitter, faYoutube, faInstagram, faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';

library.add(fab, faTwitter, faYoutube, faInstagram, faLinkedin, faGithub);

const {
  HOMEPAGE,
  ABOUT,
  WORK_HISTORY,
  SANDBOX,
  SIGNUPFORM
} = Routes;

function App() {
  return (
    <div className="app-container">
      <NavBar />
       <BrowserRouter>
        <Route exact path={HOMEPAGE} component={Home} />
        <Route exact path={ABOUT} component={About} />
        <Route exact path={WORK_HISTORY} component={Work} />
        <Route exact path={SANDBOX} component={Sandbox} />
        <Route exact path={SIGNUPFORM} component={SignUpForm} />
        <Route exact path={"/sign-up-form-text-only"} component={SignUpFormTextOnly} />
        <Route exact path={"/sign-up-form-with-button"} component={SignUpFormWithButton} />
        <Route exact path={"/sign-up-form-with-urlemail"} component={SignUpFormWithUrlEmail} />
      </BrowserRouter>
    </div>
  );
}

export default App;
