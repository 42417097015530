import React from 'react';
import './about.css';

function About(props) {
  return (
    <div className="hero-container segment-container d-flex flex-column align-items-center">
      <div className="segment-wrapper d-flex flex-column align-items-start">
        <div style={{marginBottom: '50px'}} className="header-1-black">About</div>
        <div>
          <p style={{textAlign: "start"}} className="body-copy-black">
            I have 10 years of software engineering experience and 8 years working in industry. 5 of these years have been at companies with less than 10 people. I have seen two companies through successful
            seed round raises, and one company through a Series A.
          </p>
          <p style={{textAlign: "start"}} className="body-copy-black">
            I have worked across the entire application stack for many products and projects I have been involved with, including the back-end, front-end mobile, databases, machine learning, and servers & infrastructure. I have built machine learning algorithms, ETL systems, web crawlers, and even a mobile application. Throughout my time as an engineer, I have made it
            a point to learn as many things as possible because I love what I do and I love learning about how things work.
          </p>
          <p style={{textAlign: "start"}} className="body-copy-black">
           I hope to contribute to making the engineering spaces I am a part of positive and welcoming for all.
           I want people to feel comfortable entering and staying in the engineering world so that way they can use their own positive traits to
           participate and grow however they see fit. With any team I work with, I believe that I am a genius in a room full of geniuses.
          </p>
        </div>
        <div>
          <div style={{marginBottom: '50px', marginTop: '50px'}} className="header-1-black">Some technologies and things used for this site</div>
          <ul className="body-copy-black">
            <li>React</li>
            <li>React Router</li>
            <li>S3</li>
            <li>Create React App</li>
            <li>Cloudfront</li>
            <li>Bootstrap (Flexbox styles)</li>
            <li>Figma</li>
          </ul>
        </div>
      </div>

    </div>
  );
}

export default About;
